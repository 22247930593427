.highcharts-loading {
    display: flex;
    justify-content: center;
}

.highcharts-loading-inner {
    position: relative !important;
    display: block;
    width: 56px !important;
    height: 56px !important;
    border-radius: 50%;
    background: radial-gradient(farthest-side,#1677ff 94%,#0000) top/9px 9px no-repeat, conic-gradient(#0000 30%,#1677ff);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
    animation: spinner-c7wet2 1s infinite linear;
 }
 
 @keyframes spinner-c7wet2 {
    100% {
       transform: rotate(1turn);
    }
 }